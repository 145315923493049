import Vue from 'vue'
import App from './App.vue'
import './pwa/registerServiceWorker'
import router from './router'
import store from './store'
import axios from "axios";
import config from "./config";
import moment from "moment";

import './assets/css/font.scss'

/**
 * Import Boostrap
 * Import Boostrap Icons
 */

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

/**
 * Import Vuelidate
 */
import {Vuelidate} from "vuelidate";
Vue.use(Vuelidate)


/**
 * Import Vue Meta
 */
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.prototype.$config = config
Vue.prototype.$moment = moment

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
