<template>
  <div id="app" :style="`--reality-portal-color: ${portalColor};`">
    <router-view/>
  </div>
</template>

<script>
import update from "@/pwa/update"

export default {
	mixins: [update],
	created() {
		this.getPortal()
	},
	methods: {
		getPortal() {
			// if (this.$store.getters.portal.id) return;
			
			//TODO prepnut url
			const portalUrl = window.location.host
			
			// doplnit url pre lokalne testovanie
			// const portalUrl = 'troligareality.sk'
			
			this.$axios.get( `${this.$config.API_URL}portal/domain/${portalUrl}`)
				.then((res) => {
					console.warn('get portal', res.data)
					this.$store.dispatch('portal', res.data.data)
				})
		}
	},
	computed: {
		portalColor: {
			get() {
				return this.$store.getters.portal?.portal?.color
			}
		},
		portalLogo: {
			get() {
				return this.$store.getters.portal?.logo
			}
		},
		url() {
			return window.location.href
		},
		metaTitle() {
			return this.$store.getters.portal?.portal?.meta?.title
		},
		metaDescription() {
			return this.$store.getters.portal?.portal?.meta?.description
		},
		metaKeywords() {
			return this.$store.getters.portal?.portal?.meta?.keywords
		},
		metaRobots() {
			return this.$store.getters.portal?.portal?.meta?.robots
		},
		metaFavicon() {
			return this.$store.getters.portal?.favicon
		}
	},
	metaInfo() {
		return {
			// title: this.metaTitle,
			titleTemplate: (titleChunk) => {
				// If undefined or blank then we don't need the hyphen
				return titleChunk ? `${titleChunk} | ${this.metaTitle}` : this.metaTitle;
			},
			meta: [
				{name: 'description', content: this.metaDescription},
				{name: 'keywords', content: this.metaKeywords},
				{name: 'robots', content: this.metaRobots},
				{property: 'og:title', content: this.metaTitle},
				{property: 'og:description', content: this.metaDescription},
				{property: 'og:image', content: this.portalLogo},
				{property: 'og:url', content: this.url},
				{property: 'og:type', content: 'website'}
			],
			link: [
				{rel: 'icon', href: this.metaFavicon}
			]
		}
	}
}
</script>

<style lang="scss">

</style>
