import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    portal: state.portal,
    lastVisited: state.lastVisited
  }), //only save navigation module
})

export default new Vuex.Store({
  state: {
    portal: {},
    lastVisited: []
  },
  getters: {
    portal(state) {
      return state.portal
    },
    lastVisited(state) {
      return state.lastVisited
    },
  },
  mutations: {
    setPortal(state, payload) {
      state.portal = payload
    },
    setLastVisited(state, payload) {
      state.lastVisited = payload
    },
  },
  actions: {
    portal({commit}, payload) {
      commit('setPortal', payload)
    },
    setLastVisited({commit}, payload) {
      commit('setLastVisited', payload)
    },
    lastVisited({commit, state}, payload) {
      const newState = state.lastVisited.filter(item => item !== payload)
      newState.unshift(payload)

      commit('setLastVisited', newState)
      // let isDuplicate = false
      // state.lastVisited.map(item => {
      //   if (item === payload) {
      //     isDuplicate = true
      //   }
      // })
      //
      // if (!isDuplicate) {
      //   commit('addLastVisited', payload)
      // }
    }
  },
  plugins: [vuexLocal.plugin],
})
