import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/detail/:slug',
    name: 'Detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Detail.vue')
  },
  {
    path: '/clanok/gdpr',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    }
    if (_from.name === 'Home' && to.name === 'Home') {
      console.warn('no scroll on home')
    }
    // else if (to.name === _from.name) {
    //   console.warn('same name', savedPosition)
    //   return {top: 0}
    // }
    else {
      console.log("moving to top of the page");
      window.scrollTo(0, 0);
    }
  }
})

export default router
